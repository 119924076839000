import React from "react";
import {CartItem} from "Api/Query/CartQuery";
import {formatMoneyValue} from "Components/Money/MoneyAmount";
import {DISCOUNT_TYPE_ORDINARY_ID} from "Components/InscriptionOrder/DiscountTypeSelect";

interface Props{
    item: CartItem|any,
    inscription?: any
}

export const CartItemSpecifications: React.FC<Props> = (props) => {

    function createActivityContent(item: CartItem, inscription: any = null) {
        let description = `
            <strong>Nombre del menor:</strong> ${item.child.name}<br /> 
            <strong>Centro:</strong> ${item.center.name}<br /> 
            <strong>Actividad:</strong> ${item.name}<br />`

        if (item.activitySession) {
            description += `<strong>Modalidad:</strong> ${item.activitySession.name}, de ${item.start.substring(0, item.start.length - 3)} a ${item.end.substring(0, item.end.length - 3)}.`
        }

        // days
        description += `<br /><strong>Días:</strong><br /><ul>`
        item.days.map(day => {
            const dayValue = ('name' in day) ? day.name : day
            const price = (item.initDate || !item.activitySession) ? '' : (': ' + item.price + ' €')
            description += `<li><strong>${dayValue}</strong>${price}</li>`
        })
        description += '</ul>'

        if (item.services.length) {
            description += `<strong>Servicios:</strong><br /><ul>`

            item.services.map(service => {
                description += `<li><strong>${service.name}</strong>: ${formatMoneyValue(service.price)}</li>`
            })

            description += `</ul>`
        }

        if (item.initDate) {
            description += `<strong>Fecha de Inicio:</strong> ${item.initDate}`
        }

        if((inscription?.discount && inscription?.discount.id !== DISCOUNT_TYPE_ORDINARY_ID) || item.extraDiscount?.value)
        {
            description += '<br /><strong>Descuentos:</strong><ul>'

            if(inscription?.discount){
                description += `<li>${inscription.discount.name}: <span class="text-danger">-${formatMoneyValue(item.discountAmount)}</span></li>`
            }

            if(item.extraDiscount){
                description += `<li>${item.extraDiscount.name}: <span class="text-danger">-${formatMoneyValue(item.extraDiscount.amount)}</span></li>`
            }

            description += '</ul>'
        }

        if(item.observations){
            description += `<br /><strong>Observaciones:</strong><p>${item.observations}</p>`
        }

        return {__html: description};
    }

    return (
        <>
            <span className={"purple d-block"}>{props.item.name}</span>
            <div dangerouslySetInnerHTML={createActivityContent(props.item, props.inscription ?? null)}></div>
        </>
    )

}
