import {http} from "Services/Http/HttpClient"

export const createFullInscriptionOrder = async (
    discountType: string = '',
    socialWorker: string = '',
    extraDiscountValue : string = ''
): Promise<unknown> => {
    return await http.post('inscription-orders/full', {
        discountType: discountType,
        socialWorker: socialWorker,
        extraDiscountValue: extraDiscountValue
    })
}

export const createInscriptionOrder = async (): Promise<unknown> => {
    return await http.post('inscription-orders')
}

export interface InscriptionOrderActivity {
    id: string,
    child: string,
    session: string,
    days: string[],
    services: string[],
    discountRequest: boolean
}

export const validateInscriptionBySocialServiceUser = async (
    id: string,
    approve: boolean
): Promise<any> => {
    return await http.post('/inscriptions/' + id + '/validate-inscription-by-social-service', {
        approve: approve
    })
}
