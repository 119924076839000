import React, {useEffect} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Badge, Button} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {getUsers} from "Api/Query/UserQuery";
import {deleteUsers, impersonateUser} from "Api/Mutation/UserMutation";
import {useMutation} from "react-query";
import {requestPasswordRecovery} from "../../Api/Mutation/SecurityMutation";
import {Notificator} from "../../Services/Notificator/Notificator";
import {appLoading} from "../../Store/loadingSlice";
import {useDispatch} from "react-redux";
import {CookieService} from "../../Services/Http/CookieService";
import {IMPERSONATION_COOKIE_NAME} from "../../Services/User/ImpersonationService";

export const AdminUserList: React.FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cookieService = new CookieService()

    const headers: string[] = [
        'NOMBRE DE USUARIO',
        'TUTOR/ES',
        'ESTADO VERIFICACIÓN',
        'EMAIL',
        'ESTADO',
        'REGISTRADO EL',
        'ÚLTIMO LOGIN',
        'ACCIONES'
    ]

    const requestMutation = useMutation({
        mutationKey: ['requestPasswordRecoveryAdminMutation'],
        mutationFn: (email: string) => requestPasswordRecovery(email),
        onSuccess: (response: any) => {
            Notificator.success('Se ha enviado un email de recuperación al usuario.')
        },
        onError: (error: any) => {
            if (error.response.status === 404) {
                Notificator.error('El email introducido no existe en nuestro sistema.')
            } else {
                Notificator.error('Se ha producido un error al recuperar la contraseña')
            }
        }
    })

    const impersonateMutation = useMutation({
        mutationKey: ['impersonateMutation'],
        mutationFn: (userId: string) => impersonateUser(userId),
        onSuccess: (response: any) => {
            const data = response.data._result
            cookieService.setCookie(IMPERSONATION_COOKIE_NAME, JSON.stringify(data), 1)
            Notificator.success('Te has impersonado correctamente')
            window.location.href = ROUTE_PATHS.DASHBOARD
        },
        onError: () => {
            Notificator.error('Error al impersonar el usuario')
        }
    })

    const resetPassword = (email: string) => {
        if(!window.confirm('¿Deseas enviar un email de recuperación de contraseña al usuario?')){
            return
        }

        requestMutation.mutate(email)
    }

    const impersonate = (userId: string) => {
        if(!window.confirm('¿Estas seguro que deseas impersonarte como este usuario?')){
            return
        }

        impersonateMutation.mutate(userId)
    }

    useEffect(() => {
        dispatch(
            appLoading({
                value: impersonateMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [impersonateMutation.isLoading])

    return (
        <RequireAuth>

            <AdminCrudList
                title={"Listado de usuarios"}
                headers={headers}
                queryMethod={getUsers}
                queryName={"adminListUsers"}
                deleteMethod={deleteUsers}
                tableRow={(item: any, key: number) =>
                    <>
                        <td className={"align-middle"}>
                            <Link to={ROUTE_PATHS.ADMIN_USER_EDIT.replace(':id', item.id)}>
                                {item.username}
                            </Link>
                        </td>
                        <td className={"align-middle"}>
                            <ul className={"py-0 my-0"}>
                                {item.tutor.name && <>
                                    <li>{item.tutor.name} {item.tutor.surnames}</li>
                                </>
                                }
                                {item.tutor2.name && <>
                                    <li>{item.tutor2.name} {item.tutor2.surnames}</li>
                                </>}
                            </ul>

                        </td>
                        <td className={"align-middle"}>
                            {
                                item.isEmailValidated ?
                                    <div className={"text-center"}><Badge bg={"success"}>Validado</Badge></div> :
                                    <div className={"text-center"}><Badge bg={"danger"}>Sin validar</Badge></div>
                            }
                        </td>
                        <td className={"align-middle"}>{item.email}</td>
                        <td className={"align-middle"}>
                            {
                                item.isActive ?
                                    <span className={"text-success"}><strong>Activo</strong></span> :
                                    <span className={"text-danger"}><strong>Inactivo</strong></span>
                            }
                        </td>
                        <td className={"align-middle"}>{item.createdAt}</td>
                        <td className={"align-middle"}>{item.lastLogin}</td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm mb-1 d-block w-100"}
                                onClick={() => navigate(ROUTE_PATHS.ADMIN_USER_EDIT.replace(":id", item.id))}
                            >
                                Modificar
                            </Button>

                            {/*&nbsp;*/}
                            {/*<Button*/}
                            {/*    className={"btn btn-warning btn-sm mb-1"}*/}
                            {/*>*/}
                            {/*    Impersonar*/}
                            {/*</Button>*/}

                            <Button
                                className={"btn btn-success btn-sm d-block w-100 mt-2"}
                                onClick={() => resetPassword(item.email)}
                            >
                                Restablecer contraseña
                            </Button>

                            <Button
                                className={"btn btn-dark btn-sm d-block w-100 mt-2"}
                                onClick={() => impersonate(item.id)}
                            >
                                Impersonar
                            </Button>

                            {
                                !item.isEmailValidated && <>
                                    <Button
                                        className={"btn btn-secondary btn-sm d-block w-100 mt-2"}
                                    >
                                        Activar
                                    </Button>
                                </>
                            }
                        </td>
                    </>
                }
                createButton={
                    <Button
                        className={"purple-btn"}
                        href={ROUTE_PATHS.ADMIN_USER_CREATED}
                    >
                        Añadir usuario
                    </Button>
                }
            />

        </RequireAuth>
    )
}
