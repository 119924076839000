import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormErrorText} from "Components/Form/FormErrorText";
import {DropzoneFile, FileUpload} from "Components/FileUpload/FileUpload";
import {v4 as uuidv4} from 'uuid';
import Toggle from "react-toggle";

export interface Props {
    maxFiles?: number
    maxFileSize?: number
    error?: string
    formLabel: string
    checked?: boolean
    comments?: string
    commentsLabel?: string
    files?: DropzoneFile[]
    hasComments: boolean
    hasFiles: boolean
    onChange: (checked: boolean, comments: string, files: DropzoneFile[]) => void
    toggleValue?: boolean
}

export const ChildFormAdditionalInfoCheck: React.FC<Props> = (props) => {

    const controlId: string = uuidv4()
    const [checked, setChecked] = useState<boolean>(props.checked ?? false)
    const [comments, setComments] = useState<string>(props.comments ?? '')
    const [files, setFiles] = useState<DropzoneFile[]>(props.files ?? [])
    const [toggle, setToggle] = useState<boolean>(props.toggleValue ?? true)

    useEffect(() => {
        props.onChange(checked, comments, files)
    }, [checked, comments, files]);

    useEffect(() => {
        if(typeof props.toggleValue !== 'undefined'){
            setToggle(props.toggleValue)
        }
    }, [props.toggleValue]);

    return (
        <>
            <Form.Group
                controlId={controlId}
                className={"mb-4"}
            >
                <label
                    htmlFor={controlId}
                    className={"d-block mb-2 fw-bold pointer"}
                >
                    {props.formLabel}
                </label>
                <Toggle
                    id={controlId}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
            </Form.Group>

            {checked === toggle && (props.hasFiles || props.hasComments) &&
                <>
                    {props.hasComments &&
                        <Form.Group
                            controlId={controlId + "_c"}
                            className={"mb-4"}
                        >
                            <label htmlFor={controlId + "_c"}>
                                <strong>{props.commentsLabel}</strong>
                            </label>
                            <textarea
                                id={controlId + "_c"}
                                className={"form-control mt-2"}
                                rows={5}
                                value={comments}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComments(e.target.value)}
                            ></textarea>
                        </Form.Group>
                    }

                    {props.hasFiles &&
                        <FileUpload
                            onChange={(files) => setFiles(files)}
                            maxFiles={props.maxFiles ?? 5}
                            maxFileSize={props.maxFileSize ?? (50 * 1024 * 1024)}
                            defaultFiles={files}
                            hasError={(props.error && props.error.length > 0 ? true : false) ?? false}
                        />
                    }

                    <FormErrorText error={props.error ?? ''}/>
                    <br/>
                </>
            }
        </>
    )

}
