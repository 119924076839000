import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useAppDispatch} from "hooks";
import {Link, useNavigate} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import {useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {exportAdminInscriptions, searchAdminInscriptions} from "Api/Query/InscriptionQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {InscriptionStatusesSelect} from "Components/InscriptionOrder/InscriptionStatusSelect";
import {SelectOption} from "Model/Select/SelectOption";
import Pagination from "rc-pagination";
import {InscriptionStatus} from "Services/Inscription/InscriptionStatus";
import {RefreshSpinIcon} from "Components/Icons/RefreshSpinIcon";

interface Props {
    showStatusFilter?: boolean,
    isSocialServiceUser?: boolean
}

export const AdminInscriptionList: React.FC<Props> = (props) => {

    const showStatusFilter = props.showStatusFilter ?? true
    const isSocialServiceUser = props.isSocialServiceUser ?? false

    const [allSelected, setAllChecked] = useState<boolean>(false)
    const [selectedInscriptions, setSelectedInscriptions] = useState<{ [key: string]: boolean }>({})
    const [inscriptions, setInscriptions] = useState<any[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const [pageSize, setPageSize] = useState<number>(12)
    const [page, setPage] = useState<number>(1)
    const [exporting, setExporting] = useState<boolean>(false)

    const defaultSearchFilters = !isSocialServiceUser ? {
        size: pageSize,
        page: page
    } : {
        size: pageSize,
        page: page,
        status: InscriptionStatus.PENDING_SOCIAL_SERVICES_APPROVEMENT
    }

    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)
    const [searchFilterValue, setSearchFilterValue] = useState<string>('')
    const [statusFilterValue, setStatusFilterValue] = useState<SelectOption | null>(null)
    const [initDateFilterValue, setInitDateFilterValue] = useState<string>('')
    const [endDateFilterValue, setEndDateFilterValue] = useState<string>('')

    const [totalRecords, setTotalRecords] = useState<number>(0)

    const getEditUrl = (item: any) => {
        if (props.isSocialServiceUser) {
            return ROUTE_PATHS.ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL.replace(':id', item.id)

        }

        return ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(':id', item.id)
    }

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const checkAll = () => {
        if (allSelected) {
            setSelectedInscriptions(initCheckboxes(inscriptions))
        } else {
            const checkboxes = selectedInscriptions
            Object.keys(checkboxes).map(key => checkboxes[key] = true)
            setSelectedInscriptions(checkboxes)
        }

        setAllChecked(!allSelected)
    }

    const isAnyRowChecked = (): boolean => {
        for (let key in Object.values(selectedInscriptions)) {
            const isChecked = Object.values(selectedInscriptions)[key]

            if (isChecked) {
                return true
            }
        }

        return false
    }

    const getCheckedIds = (): string[] => {
        const ids: string[] = []

        const keys = Object.keys(selectedInscriptions)
        const values = Object.values(selectedInscriptions)

        for (let i in values) {
            const checked = values[i]
            if (checked) {
                ids.push(keys[i])
            }
        }

        return ids
    }

    const onSelectedCheckboxRowChange = (item: any) => {
        setSelectedInscriptions({
            ...selectedInscriptions,
            [item.id]: !selectedInscriptions[item.id]
        })
    }

    const initCheckboxes = (sessions: any) => {
        const checkboxes: { [key: string]: boolean } = {}
        sessions.map(session => checkboxes[session.id] = false)
        return checkboxes
    }

    const deleteMultiple = () => {
        if (!window.confirm('¿Deseas eliminar los registros seleccionados?')) {
            return
        }

        setIsDeleting(true)
        // deleteMutation.mutate(getCheckedIds())
    }

    const query = useQuery({
        enabled: false,
        queryKey: ['adminSearchInscriptions'],
        queryFn: () => searchAdminInscriptions(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const resp = responseData.items
            setInscriptions(resp)
            setTotalRecords(responseData.total)
            setSelectedInscriptions(initCheckboxes(resp))
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminInscriptions'],
        queryFn: () => exportAdminInscriptions(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'inscripciones.csv'
            downloadCsv(responseData, filename)
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const onEnterPressed = (event: React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }
        search()
    }

    const search = () => {
        let searchFilters = {}

        if (searchFilterValue) {
            searchFilters = {...searchFilters, q: `*${searchFilterValue}*`}
        }
        if (statusFilterValue) {
            searchFilters = {...searchFilters, status: parseInt(statusFilterValue.value)}
        }
        if (initDateFilterValue) {
            searchFilters = {...searchFilters, startDate: initDateFilterValue}
        }
        if (endDateFilterValue) {
            searchFilters = {...searchFilters, endDate: endDateFilterValue}
        }

        searchFilters = {...searchFilters, size: 12}

        setSearchFilters(searchFilters)
        dispatch(appLoading({value: true, text: 'Cargando'}))
    }

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    useEffect(() => {
        query.refetch()
    }, [])

    useEffect(() => {
        query.refetch()
    }, [searchFilters])

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    const headers: string[] = [
        'SOLICITUD',
        'FECHA',
        'ESTADO'
    ]

    if(!isSocialServiceUser){
        headers.push('TOTAL')
    }

    headers.push('ACCIONES')

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Listado de solicitudes</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>
                                {/*<Button*/}
                                {/*    variant={"secondary"}*/}
                                {/*    className={"mx-2"}*/}
                                {/*>*/}
                                {/*    Eliminar Seleccionadas*/}
                                {/*</Button>*/}

                                <Button
                                    className={"purple-btn mx-4"}
                                    onClick={excelExport}
                                    disabled={exporting}
                                >
                                    {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                    <>Exportar</>
                                </Button>

                                {/*<Button*/}
                                {/*    className={"purple-btn"}*/}
                                {/*    disabled={true}*/}
                                {/*>*/}
                                {/*    Añadir solicitud*/}
                                {/*</Button>*/}
                            </div>
                        </Col>
                    </Row>

                    <div className={"row my-4"}>
                        {
                            showStatusFilter &&
                            <Col>
                                <Form.Group controlId={"statusFilter"}>
                                    <Form.Label>
                                        Estado de la solicitud
                                    </Form.Label>
                                    <InscriptionStatusesSelect
                                        onChange={(option: any) => setStatusFilterValue(option)}
                                        value={statusFilterValue}
                                    />

                                </Form.Group>
                            </Col>
                        }
                        <Col>
                            <Form.Group controlId={"initDateFilter"}>
                                <Form.Label>
                                    Fecha desde
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitDateFilterValue(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={"endDateFilter"}>
                                <Form.Label>
                                    Fecha hasta
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDateFilterValue(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => setSearchFilterValue(e.target.value)}
                                    onKeyDown={onEnterPressed}
                                />
                            </Form.Group>
                        </Col>
                        <Col className={"d-flex align-items-end"}>
                            <Button
                                className={"purple-btn"}
                                onClick={search}
                            >
                                Buscar
                            </Button>
                        </Col>
                    </div>
                    <br/>

                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            {/*<th*/}
                            {/*    style={{"width": "2%"}}*/}
                            {/*>*/}
                            {/*    <Form.Check*/}
                            {/*        type="checkbox"*/}
                            {/*        checked={allSelected}*/}
                            {/*        onChange={() => setAllChecked(!allSelected)}*/}
                            {/*    />*/}
                            {/*</th>*/}
                            {headers.map((header, key) => <th key={key}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>

                        {
                            inscriptions.length === 0 && <tr>
                                <td colSpan={5}>No hay inscripciones pendientes.</td>
                            </tr>
                        }

                        {inscriptions.map((item, key) => <tr key={key}>
                            <>
                                {/*<td>*/}
                                {/*    <Form.Check type="checkbox"/>*/}
                                {/*</td>*/}
                                <td>
                                    <Link to={getEditUrl(item)}>#{item.id} - {item.name}</Link>
                                </td>
                                <td>
                                    {item.createdAt}
                                </td>
                                <td>
                                    <Badge bg={item.status.color}>{item.status.name.toUpperCase()}</Badge>
                                </td>

                                {
                                    !isSocialServiceUser &&
                                    <td>
                                        {item.totalWithDiscount} €
                                    </td>
                                }
                                <td>
                                    <Button
                                        className={"purple-btn btn-sm"}
                                        onClick={() => navigate(getEditUrl(item))}
                                    >
                                        Modificar
                                    </Button>
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <Pagination
                                    total={totalRecords}
                                    pageSize={pageSize}
                                    current={page}
                                    onChange={onPaginationPageChange}
                                />
                            }
                        </Col>
                    </Row>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
