import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useAppDispatch} from "hooks";
import {Link} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import {useQuery} from "react-query";
import {Notificator} from "Services/Notificator/Notificator";
import {exportAdminInscriptions, exportAdminReport, searchAdminInscriptions} from "Api/Query/InscriptionQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import Pagination from "rc-pagination";
import {InscriptionStatus} from "Services/Inscription/InscriptionStatus";
import {RefreshSpinIcon} from "Components/Icons/RefreshSpinIcon";

interface Props {
    showStatusFilter?: boolean,
    isSocialServiceUser?: boolean
}

export const AdminReportList: React.FC<Props> = (props) => {

    const [inscriptions, setInscriptions] = useState<any[]>([])
    const [pageSize, setPageSize] = useState<number>(12)
    const [page, setPage] = useState<number>(1)
    const [exporting, setExporting] = useState<boolean>(false)

    const defaultSearchFilters = {
        size: pageSize,
        page: page,
        status: InscriptionStatus.COMPLETED
    }

    const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>(defaultSearchFilters)
    const [totalRecords, setTotalRecords] = useState<number>(0)

    const getEditUrl = (item: any) => {
        if(props.isSocialServiceUser){
            return ROUTE_PATHS.ADMIN_SOCIAL_SERVICES_PROFILE_INSCRIPTION_DETAIL.replace(':id', item.id)

        }

        return ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(':id', item.id)
    }

    const dispatch = useAppDispatch()

    const query = useQuery({
        enabled: false,
        queryKey: ['adminSearchInscriptions'],
        queryFn: () => searchAdminInscriptions(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data._result
            const resp = responseData.items
            setInscriptions(resp)
            setTotalRecords(responseData.total)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminReport'],
        queryFn: () => exportAdminReport(searchFilters, '-id'),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'informe.csv'
            downloadCsv(responseData, filename)
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    const onPaginationPageChange = (selectedPage: number) => {
        setPage(selectedPage)
        setSearchFilters({
            ...searchFilters,
            page: selectedPage.toString()
        })
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    useEffect(() => {
        query.refetch()
    }, [])

    useEffect(() => {
        dispatch(appLoading({value: query.isLoading, text: 'Cargando'}))
    }, [query.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: query.isRefetching, text: 'Cargando'}))
    }, [query.isRefetching])

    const headers: string[] = [
        'SOLICITUD',
        'FECHA',
        'ESTADO',
        'TOTAL'
    ]

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Informes</h1>
                        </Col>
                        <Col>
                            <div className={"d-flex justify-content-end"}>
                                <Button
                                    className={"purple-btn ml-auto"}
                                    onClick={excelExport}
                                    disabled={exporting}
                                >
                                    {exporting && <><RefreshSpinIcon />&nbsp;&nbsp;</>}
                                    <>Exportar</>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <table className={"table table-bordered redTable"}>
                        <thead>
                        <tr>
                            {headers.map((header, key) => <th key={key}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>

                        {
                            inscriptions.length === 0 && <tr>
                                <td colSpan={5}>No hay inscripciones pendientes.</td>
                            </tr>
                        }

                        {inscriptions.map((item, key) => <tr key={key}>
                            <>
                                <td>
                                    <Link
                                        to={getEditUrl(item)}>{item.name}</Link>
                                </td>
                                <td>
                                    {item.createdAt}
                                </td>
                                <td>
                                    <Badge bg={item.status.color}>{item.status.name.toUpperCase()}</Badge>
                                </td>
                                <td>
                                    {item.totalWithDiscount} €
                                </td>
                            </>
                        </tr>)}
                        </tbody>
                    </table>

                    <Row>
                        <Col>
                            {totalRecords > 0 &&
                                <Pagination
                                    total={totalRecords}
                                    pageSize={pageSize}
                                    current={page}
                                    onChange={onPaginationPageChange}
                                />
                            }
                        </Col>
                    </Row>

                </Col>
            </DefaultContainer>
        </RequireAuth>
    )
}
