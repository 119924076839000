import {http} from "Services/Http/HttpClient";
import FilterBuilder from "Services/Filters/FilterBuilder";

export const exportAdminInscriptions = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const newFilters = {...filters, csv: "1"}
    return searchAdminInscriptions(newFilters, sort)
}

export const exportAdminReport = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const newFilters = {...filters, csv: "1"}
    return searchAdminReport(newFilters, sort)
}

export const searchAdminReport = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    if(!paramsString){
        paramsString += '?'
    }else{
        paramsString += '&sort=' + sort
    }

    return await http.get('inscriptions/report/admin'+ paramsString)
}

export const searchAdminInscriptions = async(
    filters: any = {},
    sort: string = 'id'
) : Promise<any[]> => {
    const queryParams = FilterBuilder.hashMapToQueryString(filters)
    let paramsString = ''

    if(queryParams){
        paramsString += '?' + queryParams
    }

    if(!paramsString){
        paramsString += '?'
    }else{
        paramsString += '&sort=' + sort
    }

    return await http.get('inscriptions/admin'+ paramsString)
}

export const getAdminInscription = async(id: string) : Promise<any[]> => {
    return await http.get(`inscriptions/${id}/admin`)
}

export const getInscriptionStatuses = async() : Promise<any[]> => {
    return await http.get(`inscriptions/statuses`)
}

export const getInscriptionObservations = async(id: string) : Promise<any[]> => {
    return await http.get(`inscriptions/${id}/observations`)
}
