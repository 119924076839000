import React from "react";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Col} from "react-bootstrap";

export const PrivacyPolicy: React.FC = () => {

    return (
        <DefaultContainer>
            <Col id={"signup"} className={"mx-md-auto"}>
                <h1>Política de privacidad</h1>
                <br/>
                <h2>Confidencialidad y Protección de Datos</h2>
                <p>Los datos personales aportados tienen como finalidad ser tratados con el objeto de gestionar la
                    preinscripción, inscripción, confección de listados, emisión de recibos, gestión de cobros y
                    comunicación con las familias sobre la participación de sus hijas/os en las actividades programadas
                    fuera del horario escolar, marcados en el Programa Sanse-Concilia.</p>

                <p>
                    <strong>Existe</strong> previsión de cesión de datos a terceros: a los centros educativos donde
                    están escolarizados
                    los menores, así como a las entidades financieras para gestionar la domiciliación de los recibos,
                    además de las que puedan existir por Ley y serán tratados conforme a la normativa vigente en materia
                    de Protección de Datos Personales. La información proporcionada <strong>no se utiliza</strong> para
                    la elaboración de
                    perfiles.
                </p>

                <p>El encargado del tratamiento de los datos es la empresa adjudicataria CULTURAL ACTEX. El responsable
                    del tratamiento de datos es el Ayuntamiento de San Sebastián de los Reyes y, ante el mismo se podrá
                    solicitar el ejercicio de los derechos mediante presentación de solicitud en las Oficinas de
                    Atención Ciudadana o Sede Electrónica del Ayuntamiento. El interesado tiene derecho a presentar
                    reclamación ante la Agencia Española de Protección de Datos cuando no haya obtenido satisfacción en
                    el ejercicio de sus derechos. Todo lo cual se le informa en cumplimiento del Art. 13 del Reglamento
                    Europeo (UE) 2016/679 de Protección de Datos Personales y al art. 11 de la Ley Orgánica 3/2018, de 5
                    de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>

                <p>Para obtención de información adicional relativa al tratamiento de datos del Ayuntamiento de San
                    Sebastián de los Reyes podrá consultarse el enlace en Sede Electrónica del Ayuntamiento: &nbsp;
                    <u>
                        <a href="https://sede.ssreyes.es/protecciondedatos"
                           target={"_blank"}>https://sede.ssreyes.es/protecciondedatos</a>
                    </u>.
                    El interesado podrá solicitar respecto a los datos que facilita su: acceso, rectificación o
                    supresión, limitación y oposición al tratamiento de los mismos, así como su portabilidad.</p>

                <p>Además puede consultar en el siguiente enlace la Política de Privacidad del Encargado del Tratamiento
                    de Datos (empresa adjudicataria Cultural Actex):</p>

                <h3>Política de Privacidad (Cultural Actex)</h3>
                <p>Cuando usted nos facilita información de carácter personal a través del sitio web
                    www.culturalactex.com (en adelante, el “Sitio Web”), se respeta su intimidad y los derechos que le
                    reconoce la normativa sobre protección de datos de carácter personal. Por ello, es importante que
                    entienda que información recabamos acerca de usted durante su visita y qué hacemos con dicha
                    información. Su visita al Sitio Web está sujeta a la presente Política sobre Tratamiento de Datos
                    Personales.</p>
                <p>Nunca comunicaremos a terceros su información de carácter personal, salvo en la forma establecida
                    en esta Política sobre Privacidad y Tratamiento de Datos o en los avisos establecidos para cada
                    supuesto en que se recojan sus datos personales.</p>
                <p>El responsable del sitio solamente podrá revelar cualquier información, incluyendo datos de
                    carácter personal, que considere necesaria para dar cumplimiento a las obligaciones
                    legales.<br/><br/>
                </p>


                <ol start={1}>1. Recogida y Uso de la Información</ol>
                <p style={{"paddingLeft": "50px"}}>
                    Al facilitarnos sus datos de carácter personal en el Sitio Web, está expresando su aceptación al
                    tratamiento y comunicación de sus datos personales en la forma contemplada en esta Política
                    sobre Tratamiento de Datos Personales. Si prefiere que el responsable del sitio no recabe
                    información personal acerca de usted, rogamos que no nos la facilite.</p>
                <p style={{"paddingLeft": "50px"}}>El responsable del sitio podrá usar la información de carácter
                    personal que nos facilite de forma
                    disociada (sin identificación personal) para fines internos, como puede ser la elaboración de
                    estadísticas. Así el responsable del sitio podrá recabar, almacenar o acumular determinada
                    información de carácter no personal referente a su uso del Sitio Web, como por ejemplo aquella
                    información que indique cuáles de nuestras páginas son más populares.</p>
                <p style={{"paddingLeft": "50px"}}>El buzón de contacto de la web tiene carácter meramente informativo,
                    sin que, en ningún caso,
                    pueda derivarse de la contestación efecto jurídico vinculante alguno.</p>

                <ol start={2}>2. Cookies y Archivos de Registro</ol>

                <p style={{"paddingLeft": "50px"}}>El responsable del sitio podrá colocar una “cookie” en el disco duro
                    de su ordenador a fin de
                    reconocerlo como usuario recurrente y personalizar su uso del Sitio Web. La cookie se guardará
                    en el disco duro de su ordenador hasta que usted la elimine. Podrá hacer que su navegador le
                    avise de la presencia de cookies o que los rechace automáticamente. Si rechaza las cookies podrá
                    seguir usando el Sitio Web, si bien ello podrá suponer la limitación en el uso de algunas de las
                    prestaciones o impedir el buen funcionamiento del Sitio Web.</p>

                <ol start={3}>3. Terceros</ol>

                <p style={{"paddingLeft": "50px"}}>El responsable del sitio podrá contratar a terceros para que
                    desempeñen funciones en su nombre en
                    relación con los fines para los que se puedan recoger sus datos personales, como por ejemplo,
                    analizar la información proporcionada, alojar sitios web o prestar servicios integrados de
                    logística. Dichos terceros podrán tener acceso a la información de carácter personal necesaria
                    para el desempeño de sus funciones, si bien no podrán utilizar dicha información para ningún
                    otro fin y regularemos nuestras relaciones con tales terceros en la manera exigida por la
                    normativa aplicable sobre protección de datos de carácter personal. Si desea más información
                    acerca del uso de cookies y las prácticas de recopilación de información y los procedimientos de
                    aceptación o rechazo por parte del usuario que tiene DoubleClick, haga clic aquí “Double Click
                    Privacy Policy” (<a href={"https://www.doubleclick.com/privacy/index.aspx"}
                                        target={"_blank"}>https://www.doubleclick.com/privacy/index.aspx</a>).</p>

                <ol start={4}>4. Enlaces</ol>

                <p style={{"paddingLeft": "50px"}}>El Sitio Web podrá contener enlaces a o desde otros sitios web. Debe
                    saber que el responsable del
                    sitio no se responsabiliza de las prácticas que, en materia de tratamiento de datos personales,
                    siguen otros sitios web. La presente Política sobre Tratamiento de Datos Personales es de
                    aplicación solamente a la información que recabamos en el Sitio Web. Le aconsejamos leer las
                    políticas sobre tratamiento de datos personales de otros sitios web con los que enlace a o desde
                    nuestro Sitio Web o que visite de otra forma.</p>

                <ol start={5}>5. Seguridad</ol>
                <p style={{"paddingLeft": "50px"}}>El responsable del sitio ha implantado diversas medidas para proteger
                    la seguridad de su
                    información personal, tanto “on line” como “off line”.</p>

                <ol start={6}>6. Protección de datos personales</ol>
                <p style={{"paddingLeft": "50px"}}>A los efectos de lo establecido en el Reglamento Europeo 2016/ 679 de
                    Protección de Datos
                    Personales (RGPD), CULTURAL ACTEX, S.L. (B-81.829.996) garantiza la confidencialidad de los
                    datos personales de que dispone. Le comunicamos que los datos facilitados forman parte de una
                    base de datos gestionada por CULTURAL ACTEX, S.L. (B-81.829.996), para la gestión de los
                    servicios contratados. Los datos serán mantenidos durante la relación contractual y no serán
                    cedidos a otras entidades sin su permiso expreso. Si desea ejercitar sus derechos de acceso,
                    rectificación, cancelación, oposición y portabilidad de los datos de carácter personal, puede
                    hacerlo a través de la dirección culturalactex@culturalactex.com, en los términos establecidos
                    en el RGPD. Puede ampliar información sobre sus derechos en cuanto a la confidencialidad de los
                    datos personales a través de la Agencia Española de Protección de Datos.</p>

            </Col>
        </DefaultContainer>
    )
}
