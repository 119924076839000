import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {useNavigate, useParams} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {AdminModalityForm} from "Views/Modalities/AdminModalityForm";
import {useDispatch} from "react-redux";
import {appLoading} from "Store/loadingSlice";
import {useMutation, useQuery} from "react-query";
import {getActivitySession} from "Api/Query/ActivityQuery";
import {updateActivitySession, UpdateActivitySessionRequest} from "Api/Mutation/ActivitySession";

export const AdminEditModality : React.FC = () => {

    const {id} = useParams()
    const dispatch = useDispatch()

    const [activitySession, setActivitySession] = useState<any>(null)

    const activitySessionQuery = useQuery({
        queryKey: ['getAdminActivitySessionDetail', id],
        queryFn: () => getActivitySession(id ?? ''),
        onSuccess: async (response: any) => {
            const activitySession = response.data._result
            console.log(activitySession)
            setActivitySession(activitySession)
        },
        onError: (error: any) => {
            Notificator.error('Error al obtener la sesión', 'Error')
        }
    })

    const activitySessionUpdate = useMutation({
        mutationFn: (body: UpdateActivitySessionRequest) => updateActivitySession(id ?? '-1', body),
        onSuccess: (response: any) => {
            Notificator.success('La modalidad se ha editado correctamente.')
            // navigate(ROUTE_PATHS.ADMIN_MODALITIES_LIST)
        },
        onError: (error: any) => {
            Notificator.error('Error al actualizar la modalidad.')
        }
    })

    useEffect(() => {
        dispatch(
            appLoading({
                value: activitySessionUpdate.isLoading,
                text: 'Cargando'
            })
        )
    }, [activitySessionUpdate.isLoading])

    useEffect(() => {
        // if (saveMutation.isLoading) {
        //     return
        // }

        dispatch(
            appLoading({
                value: activitySessionQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [activitySessionQuery.isLoading])

    const save = (fields: any) => {
        const weekDays : string[] = []
        fields.weekDays.map(day => weekDays.push(day.id ?? day.value))

        const centerIds :string[] = []
        fields.centers?.map(center => {
            centerIds.push(center.value)
        })

        const mutationBody: UpdateActivitySessionRequest = {
            centerIds: centerIds,
            name: fields.name,
            description: fields.description,
            price: fields.price,
            numberOfDays: fields.numberOfDays,
            start: fields.start.length === 5 ? fields.start + ':00' : fields.start,
            end: fields.end.length === 5 ? fields.end + ':00' : fields.end,
            billable: fields.billable,
            services: fields.serviceIds,
            weekDays: weekDays
        }
        activitySessionUpdate.mutate(mutationBody)
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <h1 className={"pb-4"}>Detalle de Modalidad</h1>
                    </Row>

                    <AdminModalityForm
                        onSave={save}
                        activity={activitySession?.activity ?? null}
                        activityType={activitySession?.activity.type ?? 0}
                        name={activitySession?.name ?? ''}
                        description={activitySession?.description ?? ''}
                        start={activitySession?.start ?? '00:00'}
                        end={activitySession?.end ?? '00:00'}
                        price={activitySession?.price ?? 0}
                        numberOfDays={activitySession?.numberOfDays ?? 0}
                        billable={activitySession?.billable ?? false}
                        weekDays={activitySession?.days ?? []}
                        services={activitySession?.services ?? []}
                        centers={activitySession?.centers ?? []}
                        isEdit={true}
                    />
                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
