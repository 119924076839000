import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {useNavigate} from "react-router-dom";
import {Notificator} from "Services/Notificator/Notificator";
import {useMutation, useQuery} from "react-query";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Cart as CartModel, CartItem, getCart} from "Api/Query/CartQuery";
import {appLoading} from "Store/loadingSlice";
import {useDispatch} from "react-redux";
import {formatMoneyValue, MoneyAmount} from "Components/Money/MoneyAmount";
import {removeCartItem} from "Api/Mutation/CartMutation";
import {decreaseCartCounter, setTotalCartCounter} from "Store/cartSlice";

export const Cart: React.FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [cart, setCart] = useState<CartModel>()
    const [cartItems, setCartItems] = useState<CartItem[]>([])

    const cartDetailQuery = useQuery({
        enabled: false,
        queryKey: ['cartDetail'],
        queryFn: () => getCart(),
        onSuccess: (data: any) => {
            const response = data.data._result
            setCart(response)
            setCartItems(response.items)
            dispatch(setTotalCartCounter(response.items.length))
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const removeCartItemMutation = useMutation({
        mutationFn: (id: string) => removeCartItem(id),
        onSuccess: () => {
            Notificator.success(`“La inscripción se ha eliminado de su carrito.`)
            cartDetailQuery.refetch()
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    useEffect(() => {
        cartDetailQuery.refetch()
    }, [])

    useEffect(() => {
        dispatch(
            appLoading({
                value: cartDetailQuery.isRefetching,
                text: 'Cargando'
            })
        )
    }, [cartDetailQuery.isRefetching])

    useEffect(() => {
        dispatch(
            appLoading({
                value: cartDetailQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [cartDetailQuery.isLoading])

    useEffect(() => {
        dispatch(
            appLoading({
                value: removeCartItemMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [removeCartItemMutation.isLoading])

    function createActivityContent(item: CartItem) {
        let description = `
            <strong>Nombre del menor:</strong> ${item.child.name}<br /> 
            <strong>Centro:</strong> ${item.center.name}<br /> 
            <strong>Actividad:</strong> ${item.name}<br />`

        if(item.activitySession){
            description += `<strong>Modalidad:</strong> ${item.activitySession.name}, de ${item.start.substring(0, item.start.length - 3)} a ${item.end.substring(0, item.end.length - 3)}.`
        }

        // days
        description += `<br /><strong>Días:</strong><br /><ul>`
        item.days.map(day => {
            const dayValue = ('name' in day) ? day.name : day
            const price = (item.initDate || !item.activitySession) ? '' : (': ' + item.price + ' €')
            description += `<li><strong>${dayValue}</strong>${price}</li>`
        })
        description += '</ul>'

        if (item.services.length) {
            description += `<strong>Servicios:</strong><br /><ul>`

            item.services.map(service => {
                description += `<li><strong>${service.name}</strong>: ${formatMoneyValue(service.price)}</li>`
            })

            description += `</ul>`
        }

        if(item.initDate){
            description += `<strong>Fecha de Inicio:</strong> ${item.initDate}`
        }

        if(item.observations){
            description += `<br /><strong>Observaciones:</strong><p>${item.observations}</p>`
        }

        return {__html: description};
    }

    const onRemoveClicked = (id: string) => {
        if(!window.confirm("¿Deseas eliminar esta inscripción del carrito?")) {
            return
        }

        removeCartItemMutation.mutate(id)
    }

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col id={"cart"}>
                    <h1 className={"pb-4"}>Carrito de inscripciones</h1>
                    <table className={"table table-bordered cartTable"}>
                        <thead>
                        <tr>
                            <th>SERVICIO</th>
                            <th>PRECIO</th>
                            <th>CANTIDAD</th>
                            <th>SUBTOTAL</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>

                        {cartItems?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td width={"50%"}>
                                            <Row className={"d-flex  align-items-top"}>
                                                <Col sm={2}>
                                                    {
                                                        item.image &&
                                                        <img src={item.image}
                                                             title={item.name}
                                                             alt={item.name}
                                                             className={"w-100"}
                                                        />
                                                    }
                                                </Col>
                                                <Col sm={10}>
                                                    <div dangerouslySetInnerHTML={createActivityContent(item)}></div>
                                                </Col>
                                            </Row>
                                        </td>
                                        <td className={"align-middle"}>
                                            <MoneyAmount amount={item.price}/>
                                        </td>
                                        <td className={"align-middle"}>
                                            1
                                        </td>
                                        <td className={"align-middle"}>
                                            <MoneyAmount amount={item.totalPrice}/>
                                        </td>
                                        <td className={"text-center align-middle"}>
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                className={"text-danger pointer"}
                                                onClick={() => onRemoveClicked(item.id)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        )}

                        {
                            (!cartItems || cartItems.length === 0) && <>
                                <tr>
                                    <td colSpan={5}>No has añadido ninguna actividad al carrito.</td>
                                </tr>
                            </>
                        }

                        </tbody>
                    </table>

                    {cart && cartItems && cartItems.length > 0 &&
                        <>
                            <span className={"d-block totalAmount"}>Total de la solicitud</span>
                            <Row>
                                <Col sm={4}>
                                    <table className={"table table-bordered cartTable"}>
                                        <tbody>
                                        <tr>
                                            <td>SUBTOTAL</td>
                                            <td>
                                                <MoneyAmount amount={cart?.totalPrice ?? 0}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>
                                                <MoneyAmount amount={cart?.totalPrice ?? 0}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <br/>
                            <Button
                                className={"purple-btn"}
                                onClick={() => navigate(ROUTE_PATHS.ACTIVITIES_LIST)}
                            >
                                Añadir más actividades
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                className={"purple-btn"}
                                onClick={() => navigate(ROUTE_PATHS.REQUEST_INVOICE_DETAIL)}
                            >
                                Ir a Descuentos y/o Finalizar Solicitud
                            </Button>
                        </>
                    }

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
