import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Button, Col, Form, Row} from "react-bootstrap";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {useMutation, useQuery} from "react-query";
import {getUserDetail, getUserRoles} from "Api/Query/UserQuery";
import {useParams} from "react-router-dom";
import {appLoading} from "Store/loadingSlice";
import {useAppDispatch} from "hooks";
import {updateUser, UserRequest} from "Api/Mutation/UserMutation";
import {Notificator} from "Services/Notificator/Notificator";

export const AdminUserEdit: React.FC = () => {

    const {id} = useParams()
    const dispatch = useAppDispatch()

    const [formData, setFormData] = useState<{ [key: string]: any }>({
        username: '',
        email: '',
        password: '',
        isActive: false,
        isEmailValidated: false,
        roleId: '',
    })
    const [userRoles, setUserRoles] = useState<any>({})

    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({
        username: false,
        email: false,
        password: false,
        isActive: false,
        isEmailValidated: false,
        roleId: false
    })

    const validate = (): boolean => {
        const requiredFields : any = ['username', 'email', 'roleId']
        let errors : number = 0

        requiredFields.map((f:string) => {
            if(!formData[f]){
                formErrors[f] = true
                errors++
            }else{
                formErrors[f] = false
            }
        })

        return errors===0
    }

    const save = () => {
        if (!validate()) {
            return
        }

        let data: any = {
            username: formData.username,
            email: formData.email,
            isActive: formData.isActive,
            isEmailValidated: formData.isEmailValidated,
            roleId: formData.roleId,
            id: id
        }

        if(formData.password){
            data = {...data, password: formData.password}
        }

        updateUserMutation.mutate(data)
    }

    const detailQuery = useQuery({
        enabled: false,
        queryKey: ['getUserDetailAdmin'],
        queryFn: () => getUserDetail(id ?? ''),
        onSuccess: (res: any) => {
            const response = res.data._result
            setFormData({
                username: response.username,
                email: response.email,
                password: '',
                isActive: response.isActive,
                isEmailValidated: response.isEmailValidated,
                roleId: response.role?.value ?? ''
            })
        }
    })

    const userRolesQuery = useQuery({
        enabled: false,
        queryKey: ['getUserRolesAdmin'],
        queryFn: () => getUserRoles(),
        onSuccess: (res: any) => {
            const response = res.data._result
            setUserRoles(response)
        }
    })

    const updateUserMutation = useMutation({
        mutationKey: ['updateUserMutation'],
        mutationFn: (body: any) => updateUser(body, body.id),
        onSuccess: () => {
            Notificator.success('El usuario se ha actualizado correctamente')
        },
        onError: () => {
            Notificator.error('Error al actualizar el usuario')
        }
    })

    useEffect(() => {
        userRolesQuery.refetch()
        detailQuery.refetch()
    }, [])

    useEffect(() => {
        dispatch(appLoading({value: detailQuery.isLoading, text: 'Cargando'}))
    }, [detailQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: userRolesQuery.isLoading, text: 'Cargando'}))
    }, [userRolesQuery.isLoading])

    useEffect(() => {
        dispatch(appLoading({value: updateUserMutation.isLoading, text: 'Cargando'}))
    }, [updateUserMutation.isLoading])

    return (
        <RequireAuth>
            <DefaultContainer>
                <Col>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Editar usuario</h1>
                        </Col>
                    </Row>

                    <Row>
                        {
                            formData.username &&
                            <Form className={"row mt-4"}>
                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"username"}>
                                        <Form.Label>
                                            Nombre de usuario
                                            &nbsp;<FormLabelRequiredAsterisk/>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                                ...formData,
                                                username: e.target.value
                                            })}
                                            className={formErrors.username ? 'is-invalid' : ''}
                                            value={formData.username}
                                        />
                                        {formErrors.username &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"email"}>
                                        <Form.Label>
                                            Email
                                            &nbsp;<FormLabelRequiredAsterisk/>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                                ...formData,
                                                email: e.target.value
                                            })}
                                            className={formErrors.email ? 'is-invalid' : ''}
                                            value={formData.email}
                                        />
                                        {formErrors.email &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"password"}>
                                        <Form.Label>
                                            Contraseña
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                                ...formData,
                                                password: e.target.value
                                            })}
                                            className={formErrors.password ? 'is-invalid' : ''}
                                            value={formData.password}
                                        />
                                        {formErrors.password &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"role"}>
                                        <Form.Label>
                                            Rol
                                            &nbsp;<FormLabelRequiredAsterisk/>
                                        </Form.Label>
                                        <Form.Select
                                            onChange={(e: React.ChangeEvent<any>) => setFormData({
                                                ...formData,
                                                roleId: e.target.value
                                            })}
                                            className={formErrors.roleId ? 'is-invalid' : ''}
                                            defaultValue={formData.roleId && parseInt(formData.roleId)}
                                        >
                                            <>
                                                <option>Selecciona un rol</option>
                                                {Object.keys(userRoles).map((roleId: any) => <option key={roleId} value={roleId}>{userRoles[roleId]}</option>)}
                                            </>
                                        </Form.Select>
                                        {formErrors.roleId &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"isActive"}>
                                        <Form.Label>
                                            Estado
                                        </Form.Label>
                                        <Form.Check
                                            label={"Activo"}
                                            type="checkbox"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                                ...formData,
                                                isActive: e.target.checked
                                            })}
                                            className={formErrors.isActive ? 'is-invalid' : ''}
                                            checked={formData.isActive}
                                        />
                                        {formErrors.isActive &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={6} className={"mb-4"}>
                                    <Form.Group controlId={"isEmailValidated"}>
                                        <Form.Label>
                                            Verificado
                                        </Form.Label>
                                        <Form.Check
                                            label={"Sí"}
                                            type="checkbox"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({
                                                ...formData,
                                                isEmailValidated: e.target.checked
                                            })}
                                            className={formErrors.isEmailValidated ? 'is-invalid' : ''}
                                            checked={formData.isEmailValidated}
                                        />
                                        {formErrors.isEmailValidated &&
                                            <p className={"text-danger pt-2"}>El campo es obligatorio</p>}
                                    </Form.Group>
                                </Col>
                            </Form>
                        }
                    </Row>

                    <Row>
                        <Col xs={12} sm={4}>
                            <Button
                                className={"purple-btn btn-sm mt-4"}
                                onClick={() => save()}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </DefaultContainer>

        </RequireAuth>
    )

}
