import React from "react";
import {Link} from "react-router-dom";

interface Props{
    route: string,
    label: string
}

export const NavbarItem : React.FC<Props> = (props) => {

    return (
        <div className={"navbar-item"}>
            <Link to={props.route}>
                {props.label}
            </Link>
        </div>
    )
}
