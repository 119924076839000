import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useQuery} from "react-query";
import {ApiListResponse} from "Api/Query/ApiListResponse";
import {SelectOptionCollection} from "Model/Select/SelectOptionCollection";
import {getInscriptionStatuses} from "Api/Query/InscriptionQuery";
import {SelectOption} from "Model/Select/SelectOption";

interface Props {
    value: SelectOption|any,
    onChange: (newValue: any) => void,
    classes?: string
}

export const InscriptionStatusesSelect: React.FC<Props> = (props) => {

    const [value, setValue] = useState<any>(null)
    const [options, setOptions] = useState<any>([])

    const {
        isLoading,
        isError,
        data,
        error,
        refetch
    } = useQuery(['getInscriptionStatuses'], () => getInscriptionStatuses())

    useEffect(() => {
        if(!data) return

        // @ts-ignore
        const response : ApiListResponse = data?.data._result

        const collection = new SelectOptionCollection()
        Object.keys(response).map((id:any) => {
            collection.addPrimitive({
                label: response[id],
                value: id
            })
        })

        setOptions(collection.toPrimitiveOptions())
    }, [data])

    useEffect(() => {
        if (!options.length) {
            return
        }

        const values = options.filter(option => parseInt(option.value) === parseInt(props.value?.value))
        setValue(values[0] ?? null)
    }, [props.value, options])

    return (
        <Select
            isLoading={isLoading}
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={props.onChange}
            className={props.classes ?? ''}
            value={value}
        />
    )

}
