import React, {useEffect, useState} from "react";
import Select from "react-select";

interface Props {
    defaultValue: any,
    onChange: (newValue: any, id: number) => void,
    classes?: string
}

export const NifTypeSelect: React.FC<Props> = (props) => {

    const [initializedValue, setInitializedValue] = useState<boolean>(false)
    const [value, setValue] = useState<any>(null)
    const options: any = [
        {
            label: 'DNI',
            value: 1
        },
        {
            label: 'NIE',
            value: 2
        },
        {
            label: 'Pasaporte',
            value: 3
        }
    ]

    useEffect(() => {
        if (!props.defaultValue || !options.length || initializedValue) {
            return
        }

        let defaultValue = props.defaultValue
        if (typeof defaultValue.value !== 'undefined') {
            defaultValue = defaultValue.value
        }

        const values = options.filter(option => option.value === defaultValue)
        setValue(values[0] ?? null)
        setInitializedValue(true)
    }, [props.defaultValue])

    return (
        <Select
            options={options}
            placeholder={""}
            noOptionsMessage={() => "No hay resultados"}
            isClearable={true}
            loadingMessage={() => "Cargando ..."}
            onChange={(option: any) => {
                props.onChange(option, option?.value)
                setValue(option)
            }}
            className={props.classes ?? ''}
            value={value}
        />
    )

}
