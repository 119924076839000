import React, {useEffect, useRef, useState} from "react"
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {login, LoginBodyRequest, LoginBodyResponse} from "Api/Mutation/SecurityMutation";
import {useMutation} from "react-query";
import {LoadingSpinner} from "Components/Loading/LoadingSpinner";
import {Notificator} from "Services/Notificator/Notificator";
import {setAuthToken} from "Services/Security/AuthService";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {DefaultContainer} from "../Shared/DefaultContainer";
import {authCheck} from "Services/Security/AuthService";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";

export const Login: React.FC = () => {

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const inputElement :React.RefObject<HTMLInputElement> = useRef(null);
    const [errors, setErrors] = useState<{[key: string] : string}>({});
    const [validated, setValidated] = useState<boolean>(false);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const validate = () : boolean => {
        const error = 'El campo es obligatorio.'
        let hasErrors = false
        let err : {[key:string] : string} = {}

        if(!username){
            err['username'] = error
            hasErrors = true
        }

        if(!password){
            err['password'] = error
            hasErrors = true
        }

        setErrors(err)

        return hasErrors
    }

    const handleSubmit = () => {
        setLoading(true)

        if(!validated){
            setValidated(true)
        }

        const hasErrors : boolean = validate()

        if(!hasErrors){
            makeLogin()
        }else{
            setLoading(false)
        }
    };

    useEffect(() => {
        if(authCheck()){
            navigate(ROUTE_PATHS.DASHBOARD)
            return
        }

        if (inputElement.current) {
            inputElement.current.focus();
        }

        dispatch(
            appLoading({
                value: false
            })
        )
    }, []);

    useEffect(() => {
        if(validated){
            validate()
        }
    }, [username, password])

    const mutation = useMutation({
        mutationFn: (body: LoginBodyRequest) => login(body.username, body.password),
        onSettled: () => setLoading(false),
        onSuccess: (response:LoginBodyResponse) => {
            const authToken = response.data._result.token

            if(authToken){
                setAuthToken(authToken)
                navigate(ROUTE_PATHS.DASHBOARD)
            }else{
                Notificator.error('Se ha producido un error inesperado. Inténtelo de nuevo.', 'Error')
            }
        },
        onError: (error: any) => {
            const errorMessage = error.response.data._error.message

            if(errorMessage === 'Credenciales Incorrectas'){
                Notificator.error('Credenciales incorrectas.', 'Error')
            }else if(errorMessage === 'Usuario no activo'){
                Notificator.error('El usuario se encuentra inactivo.', 'Error')
            }else if(
                typeof errorMessage[0] !== 'undefined' &&
                errorMessage[0] === '[username]: Expected [email] or [NIF without last character]'
            ){
                Notificator.error('Introduce un DNI o un Email para acceder.', 'Error')
            }else{
                Notificator.error('Se ha producido un error inesperado. Inténtelo de nuevo.', 'Error')
            }
        }
    })

    const makeLogin = (): void => {
        mutation.mutate({
            username: username,
            password: password
        })
    }

    const onEnterPressed = (event : React.KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        handleSubmit()
    }

    return (
        <DefaultContainer>
            <Col id={"login"} md={6} className={"mx-md-auto"}>
            <h1>Inicia Sesión en Sanse Concilia</h1>
            <Form className={"mt-4"}>
                <Form.Group controlId="user">
                    <Form.Label>DNI (solo número) o Dirección de Correo
                        Electrónico <FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onKeyDown={onEnterPressed}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                        ref={inputElement}
                        className={errors.username ? "is-invalid" : ""}
                        required
                    />
                    {errors.username && <p className={"text-danger pt-2"}>{errors.username}</p>}
                </Form.Group>
                <Form.Group
                    id={"pwdgroup"} className="my-3" controlId="password">
                    <Form.Label>Contraseña <FormLabelRequiredAsterisk/></Form.Label>
                    <Form.Control
                        type="password"
                        onKeyDown={onEnterPressed}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        required
                        className={errors.password ? "is-invalid" : ""}
                    />
                    {errors.password && <p className={"text-danger pt-2"}>{errors.password}</p>}
                </Form.Group>
                <Row className={"align-items-start"}>
                    <Col className={"col-12 d-flex justify-content-end"}>
                        <Button
                            id={"sendLoginFormBtn"}
                            variant={""}
                            className={"purple-btn"}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading && <LoadingSpinner />}
                            <span>{loading && " "}Acceder</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
            <p className={"recoveryLink"}>
                <Link to={ROUTE_PATHS.PASSWORD_RECOVERY}>¿Has perdido tu contraseña?</Link>
            </p>
            <p className={"recoveryLink"}>
                <Link to={ROUTE_PATHS.SIGNUP}>¿Todavía no estás registrado? Regístrate ahora.</Link>
            </p>
        </Col>
        </DefaultContainer>
    )
}
