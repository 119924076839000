import {http} from "Services/Http/HttpClient";
import {ACTIVITY_TYPE_WEEKLY_ID, ACTIVITY_TYPE_YEARLY_ID} from "Components/Activities/ActivityTypeSelect";

interface ActivitySessionRequest {
    activityId: string;
    name: string;
    description: string;
    price: string;
    start: string;
    end: string;
    billable: boolean;
    weekDays: any[];
    yearDays: any[];
    services: string[];
}

export const createActivitySession = async(
    body: ActivitySessionRequest
) => {
    return http.post(`/centers/activities/session`, body)
}

export const createActivity = async(
    type: number,
    body: any
) : Promise<any> => {
    let requestBody : {[key:string] : any} = {
        name: body.name,
        description: body.description,
        startDate: body.startDate,
        endDate: body.endDate,
        initPaymentDate: body.initPaymentDate,
        endPaymentDate: body.endPaymentDate,
        activityType: body.activityType,
        discounts: body.discounts,
        centerIds: body.centerIds
    }

    if(type === ACTIVITY_TYPE_YEARLY_ID){
        requestBody = { ...requestBody, days: body.days}
    }else if(type === ACTIVITY_TYPE_WEEKLY_ID){
        requestBody = { ...requestBody, sessions: body.sessions}
    }

    return http.post(`/activities/create`, requestBody)
}

export interface UpdateActivitySessionRequest {
    name: string,
    description: string,
    numberOfDays: number,
    start: string,
    end: string,
    price: number,
    billable: boolean,
    centerIds: string[],
    weekDays: string[],
    services: string[]
}

export const updateActivitySession = async(
    activitySessionId : string,
    body: UpdateActivitySessionRequest
) : Promise<any> => {
    return http.put(`/activities/session/${activitySessionId}`, body)
}

export const deleteActivitySessions = async(activitySessionIds: string[]) : Promise<any> => {
    return http.delete('/activities/session/delete', {
        data: {
            ids: activitySessionIds
        }
    })
}
