import React, {useEffect, useState} from "react";
import {Form, Row} from "react-bootstrap";
import {NifTypeSelect} from "Components/Nif/NifTypeSelect";
import {SelectOptionType} from "Model/Select/SelectOption";
import {FormLabelRequiredAsterisk} from "Components/Form/FormLabelRequiredAsterisk";
import {FormErrorText} from "../Form/FormErrorText";

interface Props {
    onChange: (name, surname, email, phone1, phone2, nifType, nif, errors) => void,
    submitted: boolean,
    defaultValues?: any
}

export const UserDataForm: React.FC<Props> = (props) => {

    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({})

    const [name, setName] = useState<string>(props.defaultValues?.name ?? '')
    const [surname, setSurname] = useState<string>(props.defaultValues?.surname ?? '')
    const [email, setEmail] = useState<string>(props.defaultValues?.email ?? '')
    const [phone1, setPhone1] = useState<string>(props.defaultValues?.phone1 ?? '')
    const [phone2, setPhone2] = useState<string>(props.defaultValues?.phone2 ?? '')
    const [nifType, setNifType] = useState<SelectOptionType | null>(props.defaultValues?.nifType ?? null)
    const [nif, setNif] = useState<string | null>(props.defaultValues?.document ?? '')

    const validate = (): { [key: string]: string } => {
        const requiredFields = {
            "name": name,
            "surname": surname,
            "email": email,
            "phone1": phone1,
            "nifType": nifType,
            "nif": nif
        }

        const emptyError = 'El campo es obligatorio.'
        const phoneLengthErrorMessage = 'El teléfono tiene que tener 9 caracteres.'

        let errors: { [key: string]: string } = {}

        // input fields validation
        for (const [key, value] of Object.entries(requiredFields)) {
            if (!value) {
                errors[key] = emptyError
            }

            if(key === 'phone1' && value && value.toString().length != 9){
                errors[key] = phoneLengthErrorMessage
            }
        }

        // phone2 format
        if(phone2 && phone2.toString().length != 9){
            errors['phone2'] = phoneLengthErrorMessage
        }

        return errors
    }

    useEffect(() => {
        const errors: { [key: string]: string } = validate()
        setValidationErrors(errors)
        props.onChange(name, surname, email, phone1, phone2, nifType, nif, errors)
    }, [
        name, surname, email, phone1, phone2, nifType, nif
    ])

    return (
        <Form className={"partialUserDataForm"}>
            <Row>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="name">
                    <Form.Label>
                        Nombre del Padre/Madre/Tutor
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.name)}
                        value={name}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.name}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="surname">
                    <Form.Label>
                        Apellidos del Padre/Madre/Tutor
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSurname(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.surname)}
                        value={surname}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.surname}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="nifType">
                    <Form.Label>
                        Seleccione DNI / NIE o Pasaporte
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <NifTypeSelect
                        defaultValue={nifType}
                        onChange={(newValue:any) => setNifType(newValue)}
                        classes={(props.submitted && validationErrors.name) ? 'is-invalid' : ''}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.nifType}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="nif">
                    <Form.Label>
                        DNI / NIE (Números y letras)
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNif(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.nif)}
                        className={"text-uppercase"}
                        value={nif ?? ''}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.nif}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="email">
                    <Form.Label>
                        Email de Padre/Madre/Tutor (9 Dígitos)
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.email)}
                        value={email}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.email}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="phone1">
                    <Form.Label>
                        Teléfono 1 de Padre/Madre/Tutor (9 Dígitos)
                        &nbsp;<FormLabelRequiredAsterisk/>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone1(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.phone1)}
                        value={phone1}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.phone1}/>}
                </Form.Group>
                <Form.Group className="mb-3 col-12 col-sm-6" controlId="phone2">
                    <Form.Label>
                        Teléfono 2 de Padre/Madre/Tutor (9 Dígitos)
                    </Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone2(e.target.value)}
                        required
                        isInvalid={!!(props.submitted && validationErrors.phone2)}
                        value={phone2}
                    />
                    {props.submitted && <FormErrorText error={validationErrors.phone2}/>}
                </Form.Group>
            </Row>
        </Form>
    )
}
