import React from "react";
import {Dropdown} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {NavbarItem} from "Views/Shared/Navbar/NavbarItem";
import {NavbarDropdownItem} from "Views/Shared/Navbar/NavbarDropdownItem";
import {useIsDesktop} from "Hooks/Window/ResizeHook";
import {ToggleMenuMobileIcon} from "Views/Shared/Navbar/ToggleMenuMobileIcon";
import {UserService} from "../../../Services/User/UserService";

export const AdminNavbar: React.FC = () => {

    const isDesktop = useIsDesktop()

    return (
        <>
            {
                isDesktop &&
                <>
                    <NavbarItem
                        label={"Inicio"}
                        route={ROUTE_PATHS.DASHBOARD}></NavbarItem>
                    <NavbarDropdownItem
                        route={'#'}
                        label={"Personas"}
                    >
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_KIDS_LIST}>
                            Niños/as
                        </Dropdown.Item>
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_USER_LIST}>
                            Usuarios
                        </Dropdown.Item>
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_SPECIAL_NEEDS_LIST}>
                            Necesidades especiales
                        </Dropdown.Item>
                    </NavbarDropdownItem>

                    <NavbarItem
                        label={"Solicitudes"}
                        route={ROUTE_PATHS.ADMIN_INSCRIPTION_LIST}
                    ></NavbarItem>

                    <NavbarDropdownItem
                        route={'#'}
                        label={"Gestión"}
                    >
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_ACTIVITIES_LIST}>
                            Actividades
                        </Dropdown.Item>
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_MODALITIES_LIST}>
                            Modalidades
                        </Dropdown.Item>
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_CENTERS_LIST}>
                            Centros
                        </Dropdown.Item>
                        <Dropdown.Item href={ROUTE_PATHS.ADMIN_COURSES_LIST}>
                            Cursos
                        </Dropdown.Item>
                    </NavbarDropdownItem>

                    <NavbarItem
                        label={"Informes"}
                        route={ROUTE_PATHS.ADMIN_REPORT_LIST}
                    ></NavbarItem>
                </>
            }

            <ToggleMenuMobileIcon/>

        </>
    )
}
