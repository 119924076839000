import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Col} from "react-bootstrap";
import {ChildForm, ChildFormData} from "Views/Kids/ChildForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "hooks";
import {appLoading} from "Store/loadingSlice";
import {useMutation, useQuery} from "react-query";
import {getChildrenDetail} from "Api/Query/ChildrenQuery";
import {Notificator} from "Services/Notificator/Notificator";
import {CreateChildBodyRequest, updateChild, uploadChildFile} from "Api/Mutation/TutorChild";
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {ChildMediaTypes} from "Model/Child/ChildMediaTypes";
import {createDropzoneFile} from "Services/File/DropzoneFile";

export const EditChild: React.FC = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const {id} = useParams()

    const [tutorId, setTutorId] = useState<string>('')
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [child, setChild] = useState<ChildFormData>()
    const [kidsFormData, setKidsFormData] = useState<ChildFormData>({
        center: {
            id: '',
            name: ''
        },
        name: '',
        surnames:'',
        courseLevel: {
            id: '',
            name: ''
        },
        birthDate: '',
        canLeaveWithoutTutor: false,
        hasDisease: false,
        hasSpecialNeeds: false,
        hasSpecialCare: false,
        hasAnyAllergyOrSpecialDiet: false,
        hasSphincterControl: true,
        hasAnySyndrome: false,
        canEatPork: false,
        hasSocialServices: false,
        canBeFilmed: false,
        nif: '',
        documentType: null,
        childDiseases: '',
        specialCareDescription: '',
        sphincterControlDescription: '',
        specialNeedsDescription: '',
        allergyOrSpecialDietDescription: '',
        anySyndromeDescription: '',
        observations: '',
        diseaseFiles: [],
        allergensFiles: [],
        disorderFiles: [],
        reqSpecialAttentionFiles: [],
        specialEducationFiles: [],
        sphinctersFiles: [],
    })
    const [loading, setLoading] = useState<boolean>(true)

    const childDetailQuery = useQuery({
        enabled: false,
        queryKey: ['getChildrenDetail', id],
        queryFn: () => getChildrenDetail(id ?? '-1'),
        onSuccess: (response: any) => {
            const childResponse = response.data._result
            setTutorId(childResponse.tutor.id)

            const specialEducationFiles: any = []

            if(childResponse.specialEducationFiles){
                childResponse.specialEducationFiles.map((file: any) => specialEducationFiles.push(createDropzoneFile(file)))
            }

            const diseaseFiles: any = []
            if(childResponse.diseaseFiles){
                childResponse.diseaseFiles.map((file: any) => diseaseFiles.push(createDropzoneFile(file)))
            }

            const allergensFiles: any = []
            if(childResponse.allergensFiles){
                childResponse.allergensFiles.map((file: any) => allergensFiles.push(createDropzoneFile(file)))
            }

            const reqSpecialAttentionFiles: any = []
            if(childResponse.reqSpecialAttentionFiles){
                childResponse.reqSpecialAttentionFiles.map((file: any) => reqSpecialAttentionFiles.push(createDropzoneFile(file)))
            }

            // sindrome o trastorno
            const disorderFiles: any = []
            if(childResponse.disorderFiles){
                childResponse.disorderFiles.map((file: any) => disorderFiles.push(createDropzoneFile(file)))
            }

            const sphinctersFiles: any = []
            if(childResponse.sphinctersFiles){
                childResponse.sphinctersFiles.map((file: any) => sphinctersFiles.push(createDropzoneFile(file)))
            }

            setChild({
                center: childResponse.center,
                name: childResponse.name,
                surnames: childResponse.surnames,
                courseLevel: childResponse.courseLevel,
                birthDate: childResponse.birthDate,
                canLeaveWithoutTutor: childResponse.canLeaveWithoutTutor,
                hasSpecialNeeds: childResponse.hasSpecialNeeds,
                hasSpecialCare: childResponse.hasSpecialCare,
                hasAnyAllergyOrSpecialDiet: childResponse.hasAnyAllergyOrSpecialDiet,
                hasSphincterControl: childResponse.hasSphincterControl,
                hasAnySyndrome: childResponse.hasAnySyndrome,
                canEatPork: childResponse.canEatPork,
                hasSocialServices: childResponse.hasSocialServices,
                canBeFilmed: childResponse.canBeFilmed,
                nif: childResponse.nif,
                documentType: {
                    id: childResponse.documentType?.id,
                    name: childResponse.documentType?.name,
                },
                hasDisease: childResponse.hasDisease,
                childDiseases: childResponse.childDiseases,
                specialCareDescription: childResponse.specialCareDescription,
                sphincterControlDescription: childResponse.sphincterControlDescription,
                specialNeedsDescription: childResponse.specialNeedsDescription,
                allergyOrSpecialDietDescription: childResponse.allergyOrSpecialDietDescription,
                anySyndromeDescription: childResponse.anySyndromeDescription,
                observations: childResponse.observations,
                diseaseFiles: diseaseFiles,
                allergensFiles: allergensFiles,
                disorderFiles: disorderFiles,
                reqSpecialAttentionFiles: reqSpecialAttentionFiles,
                specialEducationFiles: specialEducationFiles,
                sphinctersFiles: sphinctersFiles
            })

            dispatch(appLoading({value: false}))
        },
        onError: () => {
            Notificator.error('Error al cargar los datos del hijo.')
        }
    })

    const handleSubmit = (formData : ChildFormData): void => {
        if (!submitted) {
            setSubmitted(true)
        }

        setKidsFormData(formData)
    }

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadChildFile(body.childId, body.file, body.type),
        onMutate: (body: any) => {
            dispatch(appLoading({value: true, text: `Subiendo archivo: ${body.file.file.name} ...`}))
        },
        onError: (error: any) => {
            dispatch(appLoading({value: false}))

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    const mutation = useMutation({
        mutationFn: (body: CreateChildBodyRequest) => updateChild(tutorId, id ?? '-1', body),
        onMutate: () => {
            dispatch(appLoading({value: true}))
        },
        onSuccess: async (response) => {
            await uploadFiles(id ?? '-1')
        },
        onError: (error: any) => {
            dispatch(appLoading({value: false}))

            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')

            // applyServerValidationErrors(
            //     ErrorMessageBuilder.getServerErrors(error)
            // )
        }
    })

    const uploadFiles = async (childId: string) => {
        const statesWithFiles: any = {
            [ChildMediaTypes.DISEASE]: kidsFormData.diseaseFiles,
            [ChildMediaTypes.HAS_ALLERGIES_FOOD_INTOLERANCES_OR_SPECIAL_DIETS]: kidsFormData.allergensFiles,
            [ChildMediaTypes.HAS_DISORDER]: kidsFormData.disorderFiles,
            [ChildMediaTypes.NEEDS_SPECIAL_ATTENTION_AT_ACTIVITY]: kidsFormData.reqSpecialAttentionFiles,
            [ChildMediaTypes.SPECIAL_EDUCATIONAL_NEEDS]: kidsFormData.specialEducationFiles,
            [ChildMediaTypes.CONTROL_SPHINCTERS]: kidsFormData.sphinctersFiles
        }

        const files: any = []

        Object.keys(statesWithFiles).map(async (fileTypeId: string) => {
            const state = statesWithFiles[fileTypeId]
            let totalFileKeys = Object.keys(state).length

            for (let i = 0; i < totalFileKeys; i++) {
                const fileToUpload = state[i]

                files.push({
                    childId: childId,
                    file: fileToUpload,
                    type: fileTypeId
                })
            }
        })

        for(let i in files){
            if(typeof files[i].file.deleteUrl !== 'undefined'){
                continue
            }

            await uploadMutation.mutateAsync(files[i])
        }

        dispatchSuccess()
    }

    const dispatchSuccess = (): void => {
        dispatch(appLoading({value: false}))
        Notificator.success('Su hijo/a se ha modificado correctamente.')
        navigate(ROUTE_PATHS.KIDS_LIST)
    }

    const save = () => {

        let mutationBody: CreateChildBodyRequest = {
            center: kidsFormData.center.id,
            name: kidsFormData.name,
            surnames: kidsFormData.surnames,
            courseLevel: kidsFormData.courseLevel.id,
            birthDate: kidsFormData.birthDate,
            canLeaveWithoutTutor: kidsFormData.canLeaveWithoutTutor,
            hasDisease: kidsFormData.hasDisease,
            hasSpecialNeeds: kidsFormData.hasSpecialNeeds,
            hasSpecialCare: kidsFormData.hasSpecialCare,
            hasAnyAllergyOrSpecialDiet: kidsFormData.hasAnyAllergyOrSpecialDiet,
            hasSphincterControl: kidsFormData.hasSphincterControl,
            hasAnySyndrome: kidsFormData.hasAnySyndrome,
            canEatPork: kidsFormData.canEatPork,
            hasSocialServices: kidsFormData.hasSocialServices,
            canBeFilmed: kidsFormData.canBeFilmed
        }

        if (kidsFormData.nif) {
            mutationBody = { ...mutationBody, nif: kidsFormData.nif }
        }

        if (kidsFormData.documentType) {
            mutationBody = { ...mutationBody, documentType: parseInt(kidsFormData.documentType.id) }
        }

        // ¿Padece alguna enfermedad?
        if (kidsFormData.childDiseases) {
            mutationBody = { ...mutationBody, childDiseases: kidsFormData.childDiseases }
        }

        // ¿Requiere de alguna atención especial durante las actividades?
        if (kidsFormData.specialCareDescription) {
            mutationBody = { ...mutationBody, hasSpecialCareDescription: kidsFormData.specialCareDescription}
        }

        // ¿Controla esfinteres?
        if (kidsFormData.sphincterControlDescription) {
            mutationBody = { ...mutationBody, hasSphincterControlDescription: kidsFormData.sphincterControlDescription }
        }

        // ¿Tiene necesidades educativas especiales?
        if (kidsFormData.specialNeedsDescription) {
            mutationBody = { ...mutationBody, hasSpecialNeedsDescription: kidsFormData.specialNeedsDescription }
        }

        // ¿Tiene alergias, intolerancias alimentarias o dietas especiales?
        if (kidsFormData.allergyOrSpecialDietDescription) {
            mutationBody = { ...mutationBody, hasAnyAllergyOrSpecialDietDescription: kidsFormData.allergyOrSpecialDietDescription }
        }

        // ¿Padece algún síndrome o trastorno?
        if (kidsFormData.anySyndromeDescription) {
            mutationBody = { ...mutationBody, hasAnySyndromeDescription: kidsFormData.anySyndromeDescription }
        }

        if (kidsFormData.observations) {
            mutationBody = { ...mutationBody, observations: kidsFormData.observations }
        }

        console.log('mutationBody')
        console.log(mutationBody)
        mutation.mutate(mutationBody)
    }

    useEffect(() => {
        dispatch(appLoading({value: true}))
        childDetailQuery.refetch()
    }, [])

    useEffect(() => {
        if(!kidsFormData.center?.id.length){
            return
        }

        save()
    }, [kidsFormData])

    useEffect(() => {
        if(child){
            setLoading(false)
        }
    }, [child]);

    return (
        <>
            <RequireAuth>
                <DefaultContainer>
                    <Col id={"createKid"}>
                        <h1 className={"pb-4"}>Detalle de hijo/a</h1>

                        <ChildForm
                            tutorId={tutorId}
                            submitted={submitted}
                            onSubmit={handleSubmit}
                            defaultValues={child}
                            loading={loading}
                        />

                    </Col>
                </DefaultContainer>
            </RequireAuth>
        </>
    )
}
