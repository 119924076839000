import React, {useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";

interface Props {
    onChange: (htmlContent: string) => void,
    defaultContent?: string,
    height?: number
}

export const SimpleHtmlTextEditor: React.FC<Props> = (props) => {

    const editorRef = useRef(null);

    const onChange = () => {
        // @ts-ignore
        props.onChange(editorRef.current.getContent())
    }

    return (
        <>
            <Editor
                apiKey='omasrtekvd7xplq3116gf8fizp4csha2eetlosr2g8vyukcd'
                onInit={(evt, editor: any) => editorRef.current = editor}
                initialValue={ props.defaultContent ?? ''}
                onChange={onChange}
                init={{
                    height: props.height ?? 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    language: 'es',

                }}

            />
        </>
    )

}
